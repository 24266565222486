import type {MonorailProviderProps} from '~/foundation/Monorail/types';

import type {
  DefaultComponentAnalyticsContext,
  ShopLoginButtonProps,
} from './types';

export const ANALYTICS_CONTEXT_TO_FLOW_MAP: Record<
  DefaultComponentAnalyticsContext,
  string
> = {
  loginWithShop: 'default',
  loginWithShopSelfServe: 'self_serve_customer_accounts',
  loginWithShopClassicCustomerAccounts: 'classic_customer_accounts',
  loginWithShopPrequal: 'shop_pay_installments_prequal',
  loginWithShopPaymentRequest: 'payment_request',
  loginWithShopCheckoutExtension: 'default',
  loginWithShopCheckoutModal: 'checkout_modal',
  loginWithShopShopWeb: '',
  shopCashOffers: 'shop_cash_offers',
};

export function getAnalyticsProps({
  analyticsContext = 'loginWithShop',
  checkoutToken,
  checkoutVersion,
  flowVersion,
  shopId,
  source,
  uxMode,
}: ShopLoginButtonProps): MonorailProviderProps {
  return {
    analyticsContext,
    checkoutVersion,
    checkoutToken,
    flow: ANALYTICS_CONTEXT_TO_FLOW_MAP[analyticsContext],
    flowVersion,
    shopId,
    source,
    uxMode,
  };
}
